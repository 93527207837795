<template>
	<div class="wytg">
		<AddEdit
			:level1="level1Type"
			:level2="level2Type"
			ref="addEdit"
			:visible="showEdit"
			@closeDialog="closeDialog"
		/>
		<div class="form-list">
			<div class="table-search">
				<h3>发布通知公告</h3>
				<!-- 方便找页面 后期可能删除 -->
				<FormQuery ref="formquery" :data="Query" :key="keyForm" @submit="search" @reset="reset">
				</FormQuery>
				<div class="tools">
					<el-button type="success" @click="add">发布新文章</el-button>
				</div>
			</div>
			<div class="table-content">
				<table-page
					:data="tableData"
					:columns="columns"
					:currentPage="query.current"
					:pageSize="query.size"
					:total="total"
					:border="true"
					:loading="loading"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange"
					@selectionChange="handleSelection"
				>
					<template slot="index" slot-scope="scope">
						<span>{{ query.size * (query.current - 1) + scope.index + 1 }}</span>
					</template>
					<template slot="operation" slot-scope="scope">
						<el-button type="text" size="small" @click="detailClick(scope.$index, scope.row)"
							>查看</el-button
						>
						<el-button type="text" size="small" @click="editClick(scope.$index, scope.row)"
							>修改</el-button
						>
						<el-button type="text" size="small" @click="delClick(scope.row)">删除</el-button>
					</template>
				</table-page>
			</div>
		</div>
	</div>
</template>
    <script>
import FormQuery from '@/components/form/form-search'
import TablePage from '@/components/table/table-page'
import AddEdit from './addEdit'
import { getMessageType, getNewsList, removeNews } from '@/api/add'
import { fbtztgColumn } from '../tableColumn'
const Query = [
	{
		name: 'title',
		label: '文章标题：',
		componentType: 'input',
		inputType: '',
		placeholder: '',
		width: '160px',
	},
	// {
	//   name: "name1",
	//   label: "一级分类：",
	//   componentType: "select",
	//   placeholder: "请选择",
	//   width: "160px",
	//   dataOptions: [
	//     {
	//       name: "已成交",
	//       value: 0,
	//     },
	//     {
	//       name: "未成交",
	//       value: 1,
	//     },
	//   ],
	// },
	// {
	//   name: "cjType",
	//   label: "二级分类：",
	//   componentType: "select",
	//   placeholder: "请选择",
	//   width: "160px",
	//   dataOptions: [
	//     {
	//       name: "已成交",
	//       value: 0,
	//     },
	//     {
	//       name: "未成交",
	//       value: 1,
	//     },
	//   ],
	// },
]
export default {
	components: {
		FormQuery,
		TablePage,
		AddEdit,
	},
	data() {
		return {
			loading: false,
			level1Type: [],
			level2Type: [],
			showEdit: false,
			editData: null,
			keyForm: 1, //下拉框为请求来的数据时监听会用到
			form: {},
			Query: Query,
			query: {
				current: 1,
				size: 10,
        catalog: '0203',
			},
			total: 0,
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			columns: fbtztgColumn,
			tableData: [],
			options: [
				{ value: 0, label: '已成交' },
				{ value: 1, label: '未成交' },
			],
		}
	},
	watch: {
		keyForm(n, o) {
			console.log('keyForm', n)
		},
	},
	created() {
		this.getType1()
		this.getType2()
		this.getList()
	},
	methods: {
		closeDialog() {
			this.getList()
			this.showEdit = false
		},
		async getType1() {
			let res = await getMessageType({
				level: 1,
			})
			if (res.code == 0) {
				res.data.forEach(item => {
					item.name = item.remark
					item.value = item.code
				})
			}
			this.level1Type = res.data
			this.$refs.addEdit.types1 = res.data
		},
		async getType2() {
			let res = await getMessageType({
				level: 2,
			})
			if (res.code == 0) {
				res.data.forEach(item => {
					item.name = item.remark
					item.value = item.code
				})
			}
			this.level2Type = res.data
			this.$refs.addEdit.types2 = res.data
			// let hasRegion = setInterval(() => {
			//   this.Query[1].dataOptions = this.level1Type;
			//   this.Query[2].dataOptions = this.level2Type;
			//   this.keyForm++;
			//   clearInterval(hasRegion);
			// }, 100);
		},
		add() {
			this.$refs.addEdit.type = 'add'
			this.$refs.addEdit.form = {}
			this.showEdit = true
		},
		handleSelection(selects) {
			console.log(selects)
		},
		handleSelectChange() {
			this.query.current = 1
			this.getList()
		},
		handleCurrentChange(page) {
			this.query.current = page
			this.getList()
		},
		handleSizeChange(size) {
			this.query.current = 1
			this.query.size = size
			this.getList()
		},
		detailClick(index, row) {
      this.$router.push({ name: 'newsPage', query: { c: row.articleId } })
		},
		editClick(index, row) {
			this.$refs.addEdit.type = 'edit'
			this.$refs.addEdit.form = row
			this.showEdit = true
		},

		delClick(obj) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(async () => {
					let res = await removeNews({ id: obj.articleId })
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.getList()
					}
				})
				.catch(() => {
					// this.$message({
					//   type: "info",
					//   message: "已取消删除",
					// });
				})
		},
		async getList() {
			this.loading = true
			let res = await getNewsList(this.query)
			if (!res.code) {
				this.tableData = res.data.records
				this.total = parseInt(res.data.total)
				this.loading = false
			} else {
				this.$message.error('查询失败')
				this.loading = false
			}
		},
		search(params) {
			this.query = { ...this.query, article: params }
			this.getList()
		},
		submit() {},
		reset() {},
	},
}
</script>
    <style lang="less" scoped>
.form-list {
	.table-search {
		padding: 0 0 0 30px;
		/deep/.el-button {
			padding: 10px 20px !important;
		}
		.tools {
			margin-bottom: 18px;
			margin-top: 6px;
		}
	}
	.table-content {
		padding: 0 30px;
	}
}
</style>
